<script setup lang="ts">
import { useRoute } from 'vue-router';
import { onMounted, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import 'moment/dist/locale/it';
import 'moment/dist/locale/de';
import { useLoginStore } from '@/stores/loginStore';
import { useCourseStore } from '@/stores/courseStore';
import { useCartStore } from '@/stores/cartStore';
import type { CoursePrice } from '@/models/coursePrice';
import { CourseType } from '@/models/courseTypeEnum';
import { type Course } from '@/models/course';
import { Md5 } from 'ts-md5';

const lStore = useLoginStore();
const courseStore = useCourseStore();
const cartStore = useCartStore();
const route = useRoute();
const { course } = storeToRefs(courseStore);

onMounted(() => courseStore.getCourse(route.params.course as string));
watch(route, () => courseStore.getCourse(route.params.course as string));

const parentCourses = computedAsync(async () => {
    return courseStore.getParentCoursesObj(course.value);
});

const masterCourses = computedAsync(async () => {
    const mastercourses = await courseStore.getMasterCoursesObj(course.value);
    const x = mastercourses.filter(m => m.type == CourseType.Master);
    if (x && x.length > 0) {
        const y = (await Promise.all(x.map(async m => {
            const l = await courseStore.getMasterCoursesObj(m);
            l?.map(o => {
                const r = JSON.parse(JSON.stringify(m));
                o.parentCourses = [r];
                return o;
            });
            return l;
        }))).flat();

        return ([...mastercourses as Course[], ...y]).filter(f => f && f.type == CourseType.Single).sort((s, b) => (moment(s?.date ?? new Date()).valueOf()) - (moment(b?.date ?? new Date()).valueOf()));
    }
    return mastercourses.filter(f => f.type == CourseType.Single);
})

const ChildMasterCourses = computedAsync(async () => {
    const mastercourses = await courseStore.getMasterCoursesObj(course.value);
    const x = mastercourses.filter(m => m.type == CourseType.Master);
    return x;
})

const addCart = (price: CoursePrice) => {
    cartStore.addItem(course.value, price);
}

const checkPwd = () => {
    if (!route.params.pwd || !course.value.id)
        return false;
    if (Md5.hashAsciiStr(course.value.id as string + "koine-bz") == route.params.pwd)
        return true;
    return false;
}

</script>

<template v-if="course">
    <div class="adminTool max-w-[85rem] mx-auto flex lg:flex-row md:flex-col"
        v-if="lStore.isLoggedInAdmin && lStore.isAdminToolActive">
        <RouterLink :to="{ name: 'courseEdit', params: { Id: course.id ?? 'x' } }">
            <font-awesome-icon :icon="['fas', 'gear']" />
        </RouterLink>
    </div>
    <article
        class="courseDetail max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto lg:flex lg:flex-row-reverse md:flex-col">
        <div class="lg:basis-1/4 basis-full">
            <ul class="flex flex-col lg:ml-20 lg:list-outside">
                <li v-if="course.date"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'calendar']" fixed-width />
                    {{ moment(course.date).locale($t("key").toLowerCase()).format("D. MMMM YYYY") }}
                </li>
                <li v-if="masterCourses && masterCourses.length"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'hashtag']" fixed-width />
                    {{ $t("courseList.numMeetings", { nr: masterCourses.length }) }}
                </li>
                <li v-if="course.timeMorning || course.timeAfternoon"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'clock']" fixed-width />
                    {{ course.timeMorning && course.timeMorning.length == 2 ?
                        moment(course.timeMorning[0]).format("HH:mm") +
                        "-" + moment(course.timeMorning[1]).format("HH:mm") : "" }}
                    {{ course.timeAfternoon &&
                        course.timeAfternoon.length == 2 ? moment(course.timeAfternoon[0]).format("HH:mm") + "-" +
                    moment(course.timeAfternoon[1]).format("HH:mm") : "" }}
                </li>
                <li v-if="course.maxPartecipants"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'people-group']" fixed-width />
                    {{ $t("courseList.maxPartecipants", { nr: course.maxPartecipants }) }}
                </li>
                <li v-if="course.credits"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'tag']" fixed-width />
                    <span v-html="course.credits" />
                </li>
                <li v-if="course.trainer?.name || course.trainer2?.name"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'chalkboard-teacher']" fixed-width />
                    <RouterLink :to="{ name: 'speaker', params: { Id: course.trainer?.id } }" v-if="course.trainer?.name">
                        {{ course.trainer?.name }}
                    </RouterLink>
                    <RouterLink :to="{ name: 'speaker', params: { Id: course.trainer2?.id } }" v-if="course.trainer2?.name">
                        {{ course.trainer2?.name }}
                    </RouterLink>
                </li>
                <li v-if="course.type == CourseType.Single && course.isOnlineCourse"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'globe']" fixed-width />
                    {{ $t("courseDetail.online") }}
                </li>
                <li v-if="!course.isOnlineCourse"
                    class="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <font-awesome-icon :icon="['fas', 'location-dot']" fixed-width />
                    <span v-html="course.address[$t('key')]"></span>
                </li>
            </ul>
            <single-element v-if="course.trainer?.imageUrl" class="flex flex-col lg:ml-20 mt-5 bg-white"
                :image="course.trainer?.imageUrl" :href="{ name: 'speaker', params: { Id: course.trainer?.id } }">{{
                    course.trainer?.name }}</single-element>
            <single-element v-if="course.trainer2?.imageUrl" class="flex flex-col lg:ml-20 mt-5 bg-white"
                :image="course.trainer2?.imageUrl" :href="{ name: 'speaker', params: { Id: course.trainer2?.id } }">{{
                    course.trainer2?.name }}</single-element>
        </div>
        <div class="lg:basis-2/3 basis-full mt-5 lg:mt-0">
            <div class="bg-white rounded-lg shadow-md p-6 mb-16"
                v-if="checkPwd() || (lStore.isLoggedInAdmin && lStore.isAdminToolActive)">
                <h1 class="text-3xl font-bold">{{ $t('courseDetail.textForSubscribers') }}</h1>
                <p class="mt-12 text-lg" v-html="course.textForSubscribers[$t('key')]"></p>
            </div>
            <h1 class="text-3xl font-bold" v-html="course.title[$t('key')]"></h1>
            <p class="mt-12 text-lg" v-html="course.content[$t('key')]"></p>
            <el-table :data="masterCourses" style="width: 100%" v-if="masterCourses && masterCourses.length">
                <el-table-column prop="date" :label="$t('courseDetail.masterTable.date')" width="100">
                    <template #default="scope">
                        {{ scope.row.date ? moment(scope.row.date).format("DD/MM/YYYY") : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="timeMorning" :label="$t('courseDetail.masterTable.hours')" width="180">
                    <template #default="scope">
                        {{ scope.row.timeMorning && scope.row.timeMorning.length == 2 ?
                            moment(scope.row.timeMorning[0]).format("HH:mm") +
                            "-" + moment(scope.row.timeMorning[1]).format("HH:mm") : "" }}
                        {{ scope.row.timeAfternoon &&
                            scope.row.timeAfternoon.length == 2 ? moment(scope.row.timeAfternoon[0]).format("HH:mm") + "-" +
                        moment(scope.row.timeAfternoon[1]).format("HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="title" :label="$t('courseDetail.masterTable.title')">
                    <template #default="scope">
                        <RouterLink
                            :to="{ name: 'courseDetail', params: { course: scope.row.id, lang: route.params.lang } }">
                            <div v-html="scope.row.title[$t('key')]" />
                        </RouterLink>
                    </template>
                </el-table-column>
            </el-table>

            <div>
                <div class="mx-auto">
                    <div class="flex justify-between flex-wrap">
                        <!-- Card -->
                        <div v-for="(pr, i) in course.coursePrices" :key="i"
                            class="bg-white lg:basis-64 basis-full flex flex-col border border-gray-200 text-center rounded-xl p-8 mt-8">
                            <h4 class="font-medium text-lg text-gray-800" v-html="pr.description[$t('key')]"></h4>

                            <div class="h-full bg-white lg:mt-px lg:py-5 px-8 mt-auto flex">
                                <span class="mt-7 font-bold text-5xl text-gray-800 self-end whitespace-nowrap mx-auto">
                                    {{ pr.price.toFixed(0) }}
                                    <span class="font-bold text-2xl -mr-2">&euro;</span>
                                </span>
                            </div>

                            <button @click="addCart(pr)"
                                class="mt-5 inline-flex justify-center items-center gap-2 rounded-md border-2 text-red-800 border-red-800 font-semibold hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm py-3 px-4">
                                {{ $t("courseDetail.register") }}
                            </button>
                        </div>
                        <div v-for="(pr, i) in parentCourses" :key="i"
                            class="shadow-xl bg-white basis-64 flex flex-col border border-gray-500 text-center rounded-xl p-8 mt-8">
                            <p class="mb-3"><span
                                    class="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs uppercase font-semibold bg-red-800 text-gray-100 dark:bg-blue-600 dark:text-white">{{
                                        $t("courseDetail.inMaster") }}</span></p>
                            <h4 class="font-medium text-lg text-gray-800" v-html="pr.title[$t('key')]"></h4>
                            <ul class="mt-7 space-y-2.5 text-m ml-5">
                                <li class="flex space-x-6">
                                    <svg class="flex-shrink-0 h-6 w-6 text-red-800" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.5219 4.0949C11.7604 3.81436 12.181 3.78025 12.4617 4.01871C12.7422 4.25717 12.7763 4.6779 12.5378 4.95844L6.87116 11.6251C6.62896 11.91 6.1998 11.94 5.9203 11.6916L2.9203 9.02494C2.64511 8.78033 2.62032 8.35894 2.86493 8.08375C3.10955 7.80856 3.53092 7.78378 3.80611 8.02839L6.29667 10.2423L11.5219 4.0949Z"
                                            fill="currentColor" />
                                    </svg>
                                    <span class="text-gray-800 dark:text-gray-400">
                                        {{ $t("courseDetail.numMeetings", { nr: pr.countCourses }) }}
                                    </span>
                                </li>
                            </ul>

                            <div class="h-full bg-white lg:mt-px lg:py-5 px-8 mt-auto flex">
                                <span class="self-end mr-5 mb-1">{{ $t("courseDetail.from") }}</span>
                                <span class="mt-7 font-bold text-5xl text-gray-800 self-end whitespace-nowrap mx-auto">
                                    {{ pr.coursePrices.reduce((a, b) => (a.price < b.price) ? a : b).price.toFixed(0) }}
                                        <span class="font-bold text-2xl -mr-2">&euro;</span>
                                </span>
                            </div>
                            <button
                                @click="$router.push({ name: 'courseDetail', params: { course: pr.id, lang: route.params.lang } })"
                                class="mt-5 inline-flex justify-center items-center gap-2 rounded-md border-2 text-gray-100 bg-red-800 font-semibold hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm py-3 px-4">
                                {{ $t("courseDetail.toMaster") }}
                            </button>
                        </div>
                        <div v-for="(pr, i) in ChildMasterCourses" :key="i"
                            class="shadow-s basis-64 flex flex-col border border-gray-400 text-center rounded-xl p-8 mt-8">
                            <p class="mb-3"><span
                                    class="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs uppercase font-semibold text-red-800 bg-gray-100">{{
                                        $t("courseDetail.inChildMaster") }}</span></p>
                            <h4 class="font-medium text-lg text-gray-800" v-html="pr.title[$t('key')]"></h4>
                            <ul class="mt-7 space-y-2.5 text-m ml-5">
                                <li class="flex space-x-6">
                                    <svg class="flex-shrink-0 h-6 w-6 text-red-800" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.5219 4.0949C11.7604 3.81436 12.181 3.78025 12.4617 4.01871C12.7422 4.25717 12.7763 4.6779 12.5378 4.95844L6.87116 11.6251C6.62896 11.91 6.1998 11.94 5.9203 11.6916L2.9203 9.02494C2.64511 8.78033 2.62032 8.35894 2.86493 8.08375C3.10955 7.80856 3.53092 7.78378 3.80611 8.02839L6.29667 10.2423L11.5219 4.0949Z"
                                            fill="currentColor" />
                                    </svg>
                                    <span class="text-gray-800">
                                        {{ $t("courseDetail.numMeetings", { nr: pr.masterCourses.length }) }}
                                    </span>
                                </li>
                            </ul>

                            <div class="h-full lg:mt-px lg:py-5 px-8 mt-auto flex">
                                <span class="self-end mr-5 mb-1">{{ $t("courseDetail.from") }}</span>
                                <span class="mt-7 font-bold text-5xl text-gray-800 self-end whitespace-nowrap mx-auto">
                                    {{ pr.coursePrices.reduce((a, b) => (a.price < b.price) ? a : b).price.toFixed(0) }}
                                        <span class="font-bold text-2xl -mr-2">&euro;</span>
                                </span>
                            </div>
                            <button
                                @click="$router.push({ name: 'courseDetail', params: { course: pr.id, lang: route.params.lang } })"
                                class="mt-5 inline-flex justify-center items-center gap-2 rounded-md border-2 bg-gray-100 text-red-800 font-semibold hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm py-3 px-4">
                                {{ $t("courseDetail.toChildMaster") }}
                            </button>
                        </div>
                    </div>
                    <!-- End Grid -->
                </div>
                <div class="my-3" v-if="course.footerText" v-html="course.footerText[$t('key')]" />
            </div>
        </div>
    </article>
</template>

<style >
.courseDetail ul {
    list-style-type: disc !important;
    list-style-position: inside;
}
@media (min-width: 1024px) {
    .courseDetail ul {
        list-style-position: outside;
    }
}
</style>