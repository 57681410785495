import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { Course } from '@/models/course'
import type { CoursePrice } from '@/models/coursePrice'
import type { CartItem } from '@/models/cartItem'
import type { OrderHeader } from '@/models/orderHeader'

export const useCartStore = defineStore('cart', () => {
  const items = ref(JSON.parse(localStorage.getItem('cartContent') || '[]') as CartItem[])
  const header = ref({} as OrderHeader);

  const totalPrice = computed(() => {
    return items.value.reduce(
      (accum: number, currentValue: CartItem) =>
        accum + currentValue.quantity * currentValue.price.price,
      0
    )
  })

  const totalItems = computed(() => {
    return items.value.reduce(
      (accum: number, currentValue: CartItem) => accum + currentValue.quantity,
      0
    )
  })

  const addItem = (course: Course, price: CoursePrice) => {
    /*const i = items.value.find(
      (x) => x.course.id == course.id && x.price.description == price.description
    )
    if (i) i.quantity = i.quantity + 1
    else*/
      items.value.push({
        course: course,
        price: price,
        quantity: 1,
        key: uuidv4()
      })
      localStorage.setItem('cartContent', JSON.stringify(items.value))
  }

  const clear = () => {
    items.value = []
    localStorage.setItem('cartContent', JSON.stringify(items.value))
  }

  const removeItem = (key: string) => {
    const i = items.value.find((x) => x.key == key)
    if (i) i.quantity = i.quantity - 1
    items.value = items.value.filter((x) => x.quantity > 0)

    localStorage.setItem('cartContent', JSON.stringify(items.value))
  }

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
  return { items, header, addItem, clear, removeItem, totalPrice, totalItems }
})
